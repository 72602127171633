<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";


import AddressAutoComplete from '@/components/address-auto-complete-input'

import {
    required,
} from "vuelidate/lib/validators";
import {getPMApi} from '@/api/pm'


import { postal_code_query } from '@/api/misc'

export default {

    emits: ['confirm', 'cancel', 'onDelete'],

    props: {
        property : {
            type: Object,
            default: () => {
                
            }
        },
        
    },
    components: {
        AddressAutoComplete,
    },

    validations() {
       return {
          address : {required}
       }
    },


    data() {

        return {
            apt         : '',
            address     : '',
            city        : '',
            province    : '',
            postal_code : '',
            
        }

    },
    computed: {

    },
    methods: {
        formSubmit() {
            this.$v.$touch()
            if (this.$v.$invalid == false) {
                let data = {
                    pm_id       : this.property.property_id,
                    address     : this.address,
                    city        : this.city,
                    province    : this.province,
                    postal_code : this.postal_code
                }
                getPMApi().edit_rta_address(data).then(res => {
                    if (res.errCode == 0) {
                        this.$alertify.message("Update Address Successfully");
                        this.$bvModal.hide('bto_person_edit_dialog')
                    } else {
                        this.$alertify.error("Update Address failed:" + res.errCode);
                    }
                })
            }

        },

        onAddressSelected(evt) {
            this.city            = evt.city
            this.province        = evt.province
            postal_code_query({str : evt.reference}).then(res => {
                if (res.errCode == 0) {
                    this.postal_code = res.data
                }
            })
        },





      
    },

    created() {

    },

    mounted() {
     
        this.apt             = this.property.apt
        this.city            = this.property.city_name
        this.province        = this.property.province_name
        this.postal_code     = this.property.post_code
        this.address         = this.property.street_name

        this.$refs['address_ref'].setValue(this.address)

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom02">Apt</label>
                            <input type="text" class="form-control" v-model="apt"  placeholder=""  />
                            

                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Address</label>
                            <AddressAutoComplete v-model="address" autocomplete="off" :ref="'address_ref'" :fill_full="false" :inputClass="'form-control'" @onSelected="onAddressSelected"/>
                        </div>
                    </div>

                </div>
                <!-- end row-->

                <div class="row mb-3">
                    <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-website-input" class="form-label">City</label>
                            <input type="text" class="form-control" v-model="city"  placeholder=""  />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-location-input" class="form-label">Province</label>
                            <input type="text" class="form-control" v-model="province"  placeholder=""  />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="mb-md-0">
                            <label for="contact-info-location-input" class="form-label">Postal Code</label>
                            <input type="text" class="form-control" v-model="postal_code"  placeholder=""  />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-8">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Submit</b-button>
                        </div>
                    </div>
                    <div class="col-md-2">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>