<script>


/**
 * Deposit Info TAB component
 */




// import pdf from '@teckel/vue-pdf'
// import PdfPreview from '@/components/pdf-preview.vue'
import FolderTree from "@/components/folder-tree"
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export default {

  emits: ['confirm', 'cancel'],

  props: {
    deal : {
      type: Object,
      default: () => ({
         
      })
    }
  },
  components: {
    FolderTree,
   
    VuePdfApp,
  
    ckeditor: CKEditor.component
  },

  data() {

    return {

      disable_release_btn : true,
      editor: ClassicEditor,
      selected_files: [],
      current_file : {},
      file_name : '',
      preview_file_url : '',
      pdf_previe_config: {
       
        secondaryToolbar : false,
        sidebar : false,

        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
            previous: true,
            next: true,
            pageNumber: true,
          },
          
          toolbarViewerRight : {
            presentationMode: false,
            openFile: false,
            print: true,
            download: true,
            viewBookmark: false,
          },
        }
      },

      content : ''
    }

  },


  computed: {
      btn_disable_flag() {
          return  this.deal.order_state == 'CLOSED' ||  this.deal.order_state == 'COLLAPSED'
      }
  },

  methods: {

    fileDownload(f) {
     
      this.preview_file_url = '/adm/deal/file/deal_file_download?url='+f.save_route
    },



    onActionPressed(newStatus, folder) {
      folder.folder_status = newStatus
      this.update_folder_status(folder, newStatus)
    },

    onSwitchChanged(folder) {
      this.update_folder_status(folder, folder.folder_status)
    },

   

 

    

    open_mail_dialog(attachments) {
      this.selected_files = attachments
      this.$bvModal.show('modal-send-mail-dialog')
      this.$bvModal.hide('modal-files-download-dialog')
    },

    onPdfError(e) {
      console.log(e)
    },

    openHandler(pdfApp) {
        pdfApp.pdfViewer.pdfDocument.getPage(1).then((page) => {
        var scale = 1.5;
        var viewport = page.getViewport({ scale: scale, });
        // Support HiDPI-screens.
        var outputScale = window.devicePixelRatio || 1;


        var canvas =  document.createElement('canvas');
       // var context = canvas.getContext('2d');

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = Math.floor(viewport.width) + "px";
        canvas.style.height =  Math.floor(viewport.height) + "px";
        canvas.style.position = "absolute";
        canvas.style.left = "0px";
        canvas.style.top = "0px";
        canvas.style.zIndex = "100";



        var container = document.getElementById('viewerContainer')
        container.appendChild(canvas)

      })
    },

   

    fileEdit() {
      
    }


  },

  created() {


  },

  mounted() {
      
  }

};
</script>

<template>
  <div class="row">

    <div class="row" >
      <div class="col-md-6">
        <div class="mb-3">
          <h5 class="card-title">
            Folders
            <span class="text-muted fw-normal ms-2"></span>
          </h5>
        </div>
      </div>

      <div class="col-md-6">
        <div class="d-flex flex-wrap align-items-start justify-content-end gap-2 mb-3">
          <button type="button" class="btn btn-light" v-b-modal.modal-post-message-dialog>Post Message</button>

          
          <button type="button" class="btn btn-light" v-b-modal.modal-file-upload-dialog>
            Upload Files
          </button>

          <button type="button" class="btn btn-light" v-b-modal.modal-files-download-dialog>
            Download Files
          </button>



          <b-modal centered id="modal-post-message-dialog" title="Post Message" size="lg" hide-footer style="z-index: 999999" >
           
              <form class="needs-validation" @submit.prevent="postMessage">

                  <div class="mb-3">
                    <ckeditor v-model="content" :editor="editor"></ckeditor>
                  </div>

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$bvModal.hide('modal-post-message-dialog')">Cancel</b-button>
                            <b-button variant="primary" type="submit"> Post </b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->
              </form>
          
          </b-modal>

          
        </div>
      </div>
    </div>
    <!-- end row -->

    

    <div class="row"  style="min-height: 70dvh;">
      <div class="col-md-6">
        <FolderTree ref="folderTree" :folders="deal.folder_group" 
          :show_tag="true" :show_status="true" :show_actions="true"
          :actions="['Approve','Missing','Missing Initial','Missing Sign']" :show_file_edit_actions="true" :file_actions="['Edit','Download','Delete']"
           @fileDownload="fileDownload"  @actionPressed="onActionPressed" @fileActionTriggered="fileEdit"
           @switchChanged="onSwitchChanged"
           />
      </div>
      <div class="col-md-6">
        <vue-pdf-app :config="pdf_previe_config" :pdf="preview_file_url" @open="openHandler"></vue-pdf-app> 
        <!-- <PdfPreview
          src="https://manage.mobilerealty.ca/res/deal_report/paper.pdf"
          @error="onPdfError"
        ></PdfPreview> -->
       
      </div>
    </div>
    <!-- end row -->

  </div>
</template>