<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import Choices from "choices.js";

import {
    required,
} from "vuelidate/lib/validators";
import {getPMApi} from '@/api/pm'


export default {

    emits: ['confirm', 'cancel', 'onDelete'],

    props: {
        property : {
            type: Object,
            default: () => {
                
            }
        },
        tenant: {
            type: Object,
            default: () => {
                return {
                    t_payment: '',
                    t_name: '',
                    t_institution: '',
                    t_transit: '',
                    t_account_number: '',
                    is_extra_tenant: true,
                    extra_tenant_id: '',
                    payment_amount: '',
                }

            }
        },
        tenant_list: {
            type: Array,
            default: () => []
        },
    },
    components: {
        VueBootstrapTypeahead
    },

    validations() {
        if (this.tenant.payment_type == 'PAD') {
            return {
                tenant: {
                    payment_amount: { required },
                    t_institution: { required },
                    t_transit: { required },
                    t_account_number: { required },
                }
            }
        } else {
            return {
                tenant: {
                    payment_amount: { required },
                    t_institution: {},
                    t_transit: {},
                    t_account_number: {},
                }
            }
        }
    },


    data() {

        return {
            submitted: false,
            payment_type_list: [
                { value: 'PAD', label: 'PAD', selected: false },
                { value: 'CHEQUE', label: 'Cheque', selected: false },
                { value: 'ETransfer', label: 'E-Transfer', selected: false },
            ],
            payment_type_choice: {},

            temp_native_id : -12001,

            local_tenat_list : []

            
        }

    },
    computed: {

        show_delete_btn() {
            return this.tenant.id? true : (this.tenant.extra_tenant_id < 0? true : false)
        }
    },
    methods: {
        formSubmit() {
            this.$v.$touch()
            console.log(this.$v)
            if (this.$v.$invalid == false) {
                this.onSubmitTenant()
            }

        },

        onTenantSelected(evt) {

            this.tenant.t_name           = evt.name
            this.tenant.payment_amount   = evt.payment_amount
            this.tenant.t_payment        = evt.payment_type
            this.tenant.t_institution    = evt.bank_inst
            this.tenant.t_transit        = evt.bank_transit
            this.tenant.t_account_number = evt.bank_account
            this.tenant.extra_tenant_id  = evt.extra_tenant_id
            this.tenant.id               = evt.id
            this.tenant.is_extra_tenant  = evt.is_extra_tenant
            this.payment_type_list.map(e => e.selected = e.value == this.tenant.t_payment)

            this.payment_type_choice.clearChoices()
            this.payment_type_choice.setChoices(this.payment_type_list)
        },

        onRemove() {
            this.$alertify.confirmWithTitle(
                "Confirmation",
                "Are you sure to Delete this tenant?",
                () => {
                    if (this.tenant.extra_tenant_id < 0) {
                        this.local_tenat_list = this.local_tenat_list.filter(e => e.extra_tenant_id != this.tenant.extra_tenant_id)
                    } else {
                        this.onDeleteTenant()
                    }

                 
                    this.onTenantSelected(this.local_tenat_list[0])
                    this.$refs['tenant_name_ref'].setValue(this.local_tenat_list[0].name)
                }
            )
        },

        onAddNewTenant() {
            let new_tenant = {
                name             : 'New Tenant '+ (this.local_tenat_list.length +1),
                payment_amount   : '',
                payment_type     : 'CHEQUE',
                bank_inst        : '',
                bank_transit     : '',
                bank_account     : '',
                extra_tenant_id  : this.temp_native_id ++,
                is_extra_tenant  : true,
            }
            this.local_tenat_list.push(new_tenant)
            this.onTenantSelected(new_tenant)
            this.$refs['tenant_name_ref'].setValue(new_tenant.name)
        },  

        onSubmitTenant() {
            let data = {
                pm_id                    : this.property.property_id,
                t_name                   : this.tenant.t_name,
                payment_type             : this.tenant.t_payment,
                financial_institution    : this.tenant.t_institution,
                transit                  : this.tenant.t_transit,
                account                  : this.tenant.t_account_number,
                is_extra_tenant          : this.tenant.is_extra_tenant,
                extra_tenant_id          : this.tenant.extra_tenant_id,
                payment_amount           : this.tenant.payment_amount   
            }

            //remove temp id
            if (data.extra_tenant_id <=0 ) {
                delete data.extra_tenant_id
            }

            getPMApi().update_tenant_payment_type(data).then((res)=>{
                if (res.errCode == 0) {
                this.$alertify.message("Update Tenant    Successfully");
                
                this.$bvModal.hide('tenant_payment_update_dialog')
                } else {
                this.$alertify.error("Update Tenant   failed:" + res.errCode);
                }
            })
        },

        onDeleteTenant() {
            let data = {
                pm_id                    : this.property.property_id,
                is_extra_tenant          : this.tenant.is_extra_tenant,
                extra_tenant_id          : this.tenant.extra_tenant_id,
            }

            getPMApi().remove_tenant(data).then((res)=>{
                if (res.errCode == 0) {
                this.$alertify.message("Remove Tenant  Successfully");
                
                this.$bvModal.hide('tenant_payment_update_dialog')
                } else {
                this.$alertify.error("Remove Tenant  failed:" + res.errCode +' '+ res.msg);
                }
            })
        }
    },

    created() {

    },

    mounted() {
        this.payment_type_choice = new Choices("#tenant_payment_type", {
            removeItemButton: false,
            choices: this.payment_type_list
        });

        this.local_tenat_list.push(...this.tenant_list)
        this.local_tenat_list.map(e => e.extra_tenant_id = e.id)
        this.onTenantSelected(this.local_tenat_list[0])
        this.$refs['tenant_name_ref'].setValue(this.local_tenat_list[0].name)
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom02">Tenant Name</label>
                            <vue-bootstrap-typeahead v-model="tenant.t_name" :data="local_tenat_list" :ref="'tenant_name_ref'" 
                                :serializer="s => s.name" :foramterDisplay="s => s.name" @hit="onTenantSelected($event)"
                                autocomplete="off" maxlength="22" />

                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">PAD Payment</label>
                            <select id="tenant_payment_type" v-model="tenant.t_payment" class="form-control">
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-3">
                            <label for="validationCustom01">Payment Amount</label>
                            <input type="text" class="form-control" v-model="tenant.payment_amount"
                                :class="{ 'is-invalid': $v.tenant.payment_amount.$error }" />
                            <div v-if="$v.tenant.payment_amount.$error" class="invalid-feedback">
                                <span v-if="!$v.tenant.payment_amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row mb-3">
                    <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-website-input" class="form-label">Financial Institution</label>
                            <input type="text" class="form-control" v-model="tenant.t_institution"
                                placeholder="Enter Bank  Institution Number" :disabled="tenant.t_payment != 'PAD'" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-location-input" class="form-label">Financial Transit</label>
                            <input type="text" class="form-control" v-model="tenant.t_transit"
                                placeholder="Enter Bank Transit Number" :disabled="tenant.t_payment != 'PAD'" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-md-0">
                            <label for="contact-info-location-input" class="form-label">Financial Account</label>
                            <input type="text" class="form-control" v-model="tenant.t_account_number"
                                placeholder="Enter Bank Account" :disabled="tenant.t_payment != 'PAD'" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-8">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Submit</b-button>
                            <b-button variant="danger" v-if="show_delete_btn" type="button" @click="onRemove">Delete</b-button>
                            <b-button variant="primary"  type="button" @click="onAddNewTenant">Add New tenant</b-button>
                        </div>
                    </div>
                    <div class="col-md-2">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>