<script>


/**
 * Landlord GST Setup component
 */


//  import {
//   required,
// } from "vuelidate/lib/validators";



import { financial } from '@/api/misc'
import { getPMApi } from '@/api/pm'


export default {

    emits: ['confirm', 'cancel'],

    props: {
        property : {
            type : Object,
            default: ()=> {
            }
        }
    },
    components: {
        
    },

    validations: {
       
    },
 

    data() {
      
        return {
           
        }
       
    },
    methods: {

        f(v, d = 2) {
            financial(v, d)
        },

        formSubmit() {
            this.$v.$touch()
          
            let data = {
                pm_id            : this.property.pm_id,
                enable_auto_post : this.property.is_auto_post
            }
            if (this.$v.$invalid == false) {
                getPMApi().edit_auto_post(data).then(res =>{
                    if (res.errCode == 0) {
                        this.$emit('confirm')
                        this.$alertify.message("Update  Successfully  ");
                    } else {
                        this.$alertify.error("Update  failed " + res.errCode);
                    }
                })
               
            }
            
        },
    },

    created() {
       
    },

    mounted() {
       
    },

    watch: {
       
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom02">Auto Post</label>
                            <b-form-select class="form-control" v-model="property.is_auto_post"  :options="[{value : 0, text : 'Disable'},{value : 1, text : 'Enable'}]"/>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>